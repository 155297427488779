@use '@angular/material' as mat;

@include mat.core();

$theme-primary: mat.define-palette(mat.$red-palette);
$theme-accent: mat.define-palette(mat.$grey-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$theme-warn: mat.define-palette(mat.$grey-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$theme: mat.define-light-theme((
  color: (
    primary: $theme-primary,
    accent: $theme-accent,
    warn: $theme-warn,
  ),
  typography: mat.define-typography-config(),
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($theme);

body {
  margin: 0;
  padding: 0;
  border: 0;
}
